@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: CoHeadline;
    src: local(CoHeadline-Regular),
    url(../fonts/CoHeadline-Regular.woff2) format("woff2"),
    url(../fonts/CoHeadline-Regular.woff) format("woff"),
    url(../fonts/CoHeadline-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NotoSans;
    src: local(NotoSans-Regular),
    url(../fonts/NotoSans-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NotoSans;
    src: local(NotoSans-Medium),
    url(../fonts/NotoSans-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NotoSans;
    src: local(NotoSans-Bold),
    url(../fonts/NotoSans-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  html, body, #root {
    @apply h-full w-full;
  }

  body {
    @apply bg-[#FFFFFF] text-primary-text;
  }
}

#layoutMain{
  @apply min-h-90vh;
}

.productHeaderTriangle, .productHeaderTriangleUp{
  position: relative;
}

.productHeaderTriangle::after, .productHeaderTriangleUp::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;

  width: 0;
  height: 0;
}

.productHeaderTriangle::after {
  bottom: -30px;
  border-top: 35px solid #E0E1E5;
}

.productHeaderTriangleUp::after {
  top: -30px;
  border-bottom: 35px solid #E0E1E5;
}

@media (min-width: 640px) {
  .productHeaderTriangle::after {
    border-left: 145px solid transparent;
    border-right: 145px solid transparent;
    left: 40%;
  }
}

@media(min-width: 768px){
  .productHeaderTriangleUp::after {
    display: none;
  }
}

@media (min-width: 1024px) {
  .productHeaderTriangle::after {
    left: 30%;
  }
}

@media (min-width: 1280px) {
  .productHeaderTriangle::after {
    left: 14%;
  }
}

@media (min-width: 350px) {
  .productPageAction {
    font-size: 1.4rem;
  }
}

@media (min-width: 375px) {
  .productPageAction {
    font-size: 1.5rem;
  }
}

@media (min-width: 390px) {
  .productPageAction {
    font-size: 1.6rem;
  }
}

@media (min-width: 410px) {
  .productPageAction {
    font-size: 1.7rem;
  }
}

@media (min-width: 430px) {
  .productPageAction {
    font-size: 1.8rem;
  }
}

.order-1{
  order: 1;
}

.order-2{
  order: 2;
}
.order-3{
  order: 3;
}
.order-4{
  order: 4;
}
.order-5{
  order: 5;
}
.order-6{
  order: 6;
}

@media (min-width: 640px) {
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
  .sm\:order-3 {
    order: 3;
  }
  .sm\:order-4 {
    order: 4;
  }
  .sm\:order-5 {
    order: 5;
  }
  .sm\:order-6 {
    order: 6;
  }
}

@layer components {
  h1 {
    @apply text-h1 font-sans-co;
  }

  h2 {
    @apply text-h2 font-sans-co;
  }

  h3 {
    @apply text-h3 font-sans-co;
  }

  h4 {
    @apply text-h4;
  }

  p {
    @apply text-p1;
  }

  a,
  a:active,
  a:visited {
    @apply text-link-blue decoration-0;
  }

  a:hover {
    @apply underline;
  }

  .button,
  button,
  button[type=submit],
  input[type=button],
  input[type=submit] {
    @apply text-pure-white bg-dark-grey cursor-pointer border-solid border border-dark-grey font-medium rounded-full py-sm px-md w-full max-w-xs;
  }

  button.navigationButton{
    @apply bg-lime-orange text-lime-navy border-none text-h4;
  }

  button:disabled,
  input[type=button]:disabled,
  button[type=submit]:disabled,
  input[type=button]:disabled,
  input[type=submit]:disabled
  {
    @apply text-light-grey bg-mid-grey;
  }

  .button.secondary,
  button.secondary,
  button[type=submit].secondary,
  input[type=button].secondary,
  input[type=submit].secondary {
    @apply bg-super-light-grey border-solid border border-primary-text rounded-full text-primary-text;
  }

  button.secondary:disabled,
  button[type=submit].secondary:disabled,
  input[type=button].secondary:disabled,
  input[type=submit].secondary:disabled {
    @apply text-light-grey border-super-light-grey;
  }

  @media screen(sm) {
    button,
    button[type=submit],
    input[type=button],
    input[type=submit] {
      @apply w-[12.5rem];
    }
  }
}

.swiper-wrapper{
  margin-bottom: 3rem;
}

#healthTrackerNudgeNavigation {
  position: relative;
  width: 100%;
}

#healthTrackerNudgeNavigation span {
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  bottom: -1px;
  z-index: 100;
}

#healthTrackerNudgeNavigation span img {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

#healthTrackerNudgeNavigation span:hover img {
  opacity: 1;
}

#healthTrackerNudgeNavigation span:first-of-type {
  left: 5px;
}

#healthTrackerNudgeNavigation span:last-of-type {
  right: 5px;
}

.arc-gauge text{
  fill: #004C4F !important;
  text-shadow: none !important;
  font-weight: bold !important;
}

.custom-gauge-container .pointer {
  position: relative;
}

.custom-gauge-container .pointer::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  background-image: url('../images/health-tracker/gauge-base-arm-systolic.png');
  background-size: cover;
  background-position: center;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.custom-gauge-container .pointer::before {
  z-index: 10;
}